<template>
    <div id="coi">
        <div class="iframe-login-card">
            <form ref="loginForm" :model="form">
                <div class="form-body">
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-people"></i>
                        </label>
                        <input type="text" v-model.trim="form.username" name="user" :placeholder="env==='qa'?'微盟员工测试账号':'微盟员工账号'" @change="clean_err_username">
                        <span class="error-msg-left" v-show="errors.username.show">{{ errors.username.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-lock"></i>
                        </label>
                        <input type="password" v-model.trim="form.password" name="password" placeholder="" @change="clean_err_password">
                        <span class="error-msg-left" v-show="errors.password.show">{{ errors.password.message }}</span>
                    </div>
                    <span style="font-size: 14px; font-weight:700; color: #1890ff;cursor: pointer;float: right;" @click="forgetPWD">
                        {{tip}}
                    </span>
                    <button type="button" class="form-submit-btn" @click="formSubmit">登录</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'IframeLoginCard',
        data() {
            return {
                form: {
                    username: '',
                    password: ''
                },
                errors: {
                    username: {
                        show: false,
                        message: '账户不能为空'
                    },
                    password: {
                        show: false,
                        message: '密码不能为空'
                    },
                },
                tip: '忘记密码',
            }
        },
        methods: {
            get_service () {
                let u = new URLSearchParams(window.location.search);
                return u.get('service');
            },
            validate () {
                let mark = false;
                for (let key in this.form) {
                    this.errors[key].show = this.form[key] === '';
                    mark = mark || this.errors[key].show;
                }
                return !mark;
            },
            clean_err_username () {
                this.errors.username.show = false;
                this.errors.username.message = '账户不能为空';
            },
            clean_err_password () {
                this.errors.password.show = false;
                this.errors.password.message = '密码不能为空';
            },
            formSubmit () {
                if (this.validate()) {
                    let service = this.get_service();
                    this.$http.post('/cas/login/', {
                        username: this.form.username,
                        password: this.form.password
                    }, {
                        params: {
                            service: service
                        }
                    }).then((response) => {
                        if (response.data.redirect) {
                            let autoMessage = {"op": 'redirect', 'url': response.data.redirect, 'ticket': response.data.ticket};
                            window.parent.postMessage(autoMessage,'*');
                        }
                    }).catch((error) => {
                        let error_data = error.response.data
                        if (error_data) {
                            if ([30011, 30012].indexOf(error_data.error) > -1) {
                                this.errors.password.show = true;
                                this.errors.password.message = error_data.userMessage;
                                if (error_data.error === 30012) {
                                  this.tip = '更新密码'
                                }
                            }
                            if ([30020, 30010, 30021, 30030, 30031].indexOf(error_data.error) > -1) {
                                this.errors.username.show = true;
                                this.errors.username.message = error_data.userMessage;
                            }
                        }
                    })
                }
            },
            forgetPWD () {
                let autoMessage = {"op": 'forgetPWD'};
                window.parent.postMessage(autoMessage,'*');
            }
        },
        created () {
            let params = new URLSearchParams(window.location.search)
            this.$http.post('/cas/iframe_login/?service='+ params.get('service')).then((response) => {
                if (response.data.redirect) {
                    let autoMessage = {"op": 'redirect', 'url': response.data.redirect, 'ticket': response.data.ticket};
                    window.parent.postMessage(autoMessage,'*');
                }
            })
        }
    }

</script>
<style scoped>
    @import '../login.css';
    #coi {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
    }
</style>
