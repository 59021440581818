<template>
    <div id="fpwd">
        <div class="header">
            <img class="logo" src="@/assets/weimeng-logo.png" />
        </div>
        <div class="cpwd-box">
            <form ref="fpwdForm" :model="form">
                <div class="form-body">
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-people"></i>
                        </label>
                        <input type="text" v-model.trim="form.username" :placeholder="env==='qa'?'微盟员工测试账号':'微盟员工账号'" @change="clean_err('username')">
                        <span class="error-msg-right" v-show="errors.username.show">{{ errors.username.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-lock"></i>
                        </label>
                        <input type="password" v-model.trim="form.old_password" placeholder="原密码" @change="clean_err('old_password')">
                        <span class="error-msg-right" v-show="errors.old_password.show">{{ errors.old_password.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-lock"></i>
                        </label>
                        <input type="password" v-model.trim="form.new_password" placeholder="新密码(最少9位，包含大小写字母、数字与特殊字符，不含@，&)" @change="clean_err('new_password')">
                        <span class="error-msg-right" v-show="errors.new_password.show">{{ errors.new_password.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-querenmima-copy-copy"></i>
                        </label>
                        <input type="password" v-model.trim="form.confirm_password" placeholder="确认新密码" @change="clean_err('confirm_password')">
                        <span class="error-msg-right" v-show="errors.confirm_password.show">{{ errors.confirm_password.message }}</span>
                    </div>
                    <a style="font-size: 14px; font-weight:700; color: #1890ff;cursor: pointer;float: right;margin-top: 10px;" @click="forgetPWD">
                        忘记密码？
                    </a>
                    <button type="button" class="form-submit-btn" @click="changePWD">修改<span v-if="env==='qa'">测试账户</span>密码</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                form: {
                    action: 'change',
                    username: '',
                    old_password: '',
                    new_password: '',
                    confirm_password: '',
                },
                errors: {
                    username: {
                        show: false,
                        message: '请输入账户名'
                    },
                    old_password: {
                        show: false,
                        message: '请输入原密码'
                    },
                    new_password: {
                        show: false,
                        message: '请输入新密码'
                    },
                    confirm_password: {
                        show: false,
                        message: '请再次输入新密码确认'
                    },
                }

            }
        },
        methods: {
            validate () {
                let mark = false;
                for (let key in this.form) {
                    if (key !== 'action') {
                        this.errors[key].show = this.form[key] === '';
                        mark = mark || this.errors[key].show;
                    }
                }
                return !mark;
            },
            clean_err (key) {
                this.errors[key].show = false;
                switch (key) {
                    case 'username':
                        this.errors[key].message = '请输入账户名';
                        break;
                    case 'old_password':
                        this.errors[key].message = '请输入原密码';
                        break;
                    case 'new_password':
                        this.errors[key].message = '请输入新密码';
                        break;
                    case 'confirm_password':
                        this.errors[key].message = '请再次输入新密码确认';
                        break;
                    default:
                        break;
                }
            },
            changePWD () {
                if (this.validate()) {
                    this.$http.post('/cas/modify/', this.form).then((response) => {
                        if (response.status == 200) {
                            alert(response.data.userMessage);
                            history.back();
                        }
                    }).catch((error) => {
                        let error_data = error.response.data;
                        if (error_data) {
                            if ([30030, 30031, 60001].indexOf(error_data.error) > -1) {
                                this.errors.username.message = error_data.userMessage;
                                this.errors.username.show = true;
                            }
                            if ([60002].indexOf(error_data.error) > -1) {
                                this.errors.old_password.show = true;
                                this.errors.old_password.message = error_data.userMessage;
                            }
                            if ([60003].indexOf(error_data.error) > -1) {
                                this.errors.new_password.show = true;
                                this.errors.new_password.message = error_data.userMessage;
                            }
                            if ([60004].indexOf(error_data.error) > -1) {
                                this.errors.confirm_password.show = true;
                                this.errors.confirm_password.message = error_data.userMessage;
                            }
                        }
                    })
                }
            },
            forgetPWD () {
                this.$router.push({
                    name: 'forget_pwd'
                });
            }
        },
        computed: {
          env () {
            return process.env.VUE_APP_ENV
          }
        },
        mounted () {}
    }
</script>
<style>
    @import '../login.css';
    #fpwd {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        background: url("../../assets/bac.svg");
    }
    .header {
        position: fixed;
        top: 0;
        width: 100%;
        height: 60px;
        background-color: rgba(60, 60, 60, 0.5);
    }
    .cpwd-box {
        width: 500px;
        height: 400px;
        background-color: rgba(255, 255, 255, 0.9);
        align-self:center;
        margin-top: -10px;
        position: relative;
        padding: 25px 25px 23px;
    }
</style>
