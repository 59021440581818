<template>
    <div class="login-box">
        <div class="login-switch" @click="switch_mode">
            <i class="iconfont oms-erweima login-icon" style="font-size: 52px" v-show="!QRmode"></i>
            <i class="iconfont oms-login-pwd login-icon" style="font-size: 40px;top:-4px;right: 0px;" v-show="QRmode"></i>
        </div>
        <div class="login-body">
            <div class="ID_form" v-show="!QRmode">
                <form ref="loginForm" :model="form">
                    <div class="form-title" style="margin-bottom: 30px;">
                        密码登录 <span v-if="env === 'qa'" style="color: #40B3DF">Alpha</span>
                    </div>
                    <div class="form-body">
                        <div class="field-group">
                            <label>
                                <i class="iconfont oms-people"></i>
                            </label>
                            <input type="text" v-model.trim="form.username" name="user" :placeholder="env==='qa'?'微盟员工测试账号':'微盟员工账号'" @change="clean_err_username">
                            <span class="error-msg-right" v-show="errors.username.show">{{ errors.username.message }}</span>
                        </div>
                        <div class="field-group">
                            <label>
                                <i class="iconfont oms-lock"></i>
                            </label>
                            <input type="password" v-model.trim="form.password" name="password" placeholder="" @change="clean_err_password">
                            <span class="error-msg-right" v-show="errors.password.show">{{ errors.password.message }}</span>
                        </div>
                        <button type="button" class="form-submit-btn" @click="formSubmit">登录</button>
                    </div>
                </form>
            </div>
            <div class="QR_form" v-show="QRmode">
                <div class="form-title">
                    扫码登录
                </div>
                <div class="form-body" style="text-align: center;">
                    <div id="qrcode" style="height: 180px;" v-show="!QRreflush"></div>
                    <div style="height: 180px;text-align: center;padding-top: 30px;" v-show="QRreflush">
                        <div class="qr-reflush" @click="reflush_qr">
                            <i  class="iconfont oms-shuaxin" style="font-size: 52px;"></i>
                        </div>
                        <br>
                        二维码已过期
                        <br>
                        点击刷新
                    </div>
                    <div style="margin-top: 10px;height: 36px;line-height: 18px;">
                        <label style="width: 36px;height: 36px;">
                            <i class="iconfont oms-qr" style="color:cornflowerblue; font-size: 36px;"></i>
                        </label>
                        <div style="position: relative;display: inline-block;font-size: 12px; padding-left: 5px;">
                            打开盟管家APP
                            <br>
                            扫一扫登录
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-footer">
            <a :href=href>{{tip}}</a>
        </div>
    </div>
</template>
<script>
    import QRCode from 'qrcode'
    export default {
        name: 'LoginCard',
        data() {
            return {
                QRmode: false,
                tip: '忘记密码',
                timer: null,
                uuid: '',
                QRreflush: false,
                form: {
                    username: '',
                    password: ''
                },
                errors: {
                    username: {
                        show: false,
                        message: '账户不能为空'
                    },
                    password: {
                        show: false,
                        message: '密码不能为空'
                    },
                },
            }
        },
        watch: {
            // 判断是否要刷新
            QRreflush: function (val) {
                if (val) {
                    clearInterval(this.timer);
                } else {
                    this.timer = setInterval(() => {
                        this.qrcode()
                    }, 1000)
                }
            },
        },
        computed: {
            href (){
                // if (window.location.hostname === 'oms.hsmob.com'){
                //     return 'https://cas.hsmob.com/#/change_pwd';
                // } else {
                //     return '/#/change_pwd'
                // }
              return `${process.env.VUE_APP_BASE_URL}/#/change_pwd`;
            },
            env () {
              return process.env.VUE_APP_ENV
            }
        },
        methods: {
            get_service () {
                let u = new URLSearchParams(window.location.search);
                return u.get('service');
            },
            switch_mode () {
                this.QRmode = !this.QRmode;
                if (this.QRmode && !this.QRreflush) {
                    if (this.get_service() !== null) {
                        this.timer = setInterval(() => {
                            this.qrcode()
                        }, 1000)
                    } else {
                        alert('请配置service参数！')
                    }
                } else {
                    clearInterval(this.timer);
                }
            },
            reflush_qr () {
                this.uuid = '';
                this.QRreflush = false;
            },
            qrcode() {
                let service = this.get_service();
                this.$http.get('/cas/qrcode/?service='+ service +'&uuid=' + this.uuid).then((response) => {
                    if (response.data.status === 'loop') {
                        if (this.uuid !== response.data.uuid){ //uuid发生变化，刷新图片
                            this.uuid = response.data.uuid;
                            // 生成二维码
                            let text = JSON.stringify({
                                'service': this.service,
                                'uuid': this.uuid,
                                'type': 3
                            });
                            QRCode.toCanvas('Weimob_OA=' + text, {
                                errorCorrectionLevel: 'L',
                                width: 180,
                            }, function (err, canvas) {
                                if (err) throw err;
                                let container = document.getElementById('qrcode')
                                container.innerHTML = '';
                                container.appendChild(canvas)
                            })
                        }
                    } else if (response.data.status === 'unloop') {
                        this.QRreflush = true;
                    } else if (response.data.status === 'redirect') {
                        clearInterval(this.timer);
                        window.location.href = response.data.redirect;
                    }
                }).catch((error) => {
                    console.log(error.response.data)
                })
            },
            validate () {
                let mark = false;
                for (let key in this.form) {
                    this.errors[key].show = this.form[key] === '';
                    mark = mark || this.errors[key].show;
                }
                return !mark;
            },
            clean_err_username () {
                this.errors.username.show = false;
                this.errors.username.message = '账户不能为空';
            },
            clean_err_password () {
                this.errors.password.show = false;
                this.errors.password.message = '密码不能为空';
            },
            formSubmit () {
                if (this.validate()) {
                    let service = this.get_service();
                    console.log(service)
                    this.$http.post('/cas/login/', {
                        username: this.form.username,
                        password: this.form.password
                    }, {
                        params: {
                            service: service
                        }
                    }).then((response) => {
                        console.log(response)
                        if (response.data.redirect) {
                            window.location.href = response.data.redirect
                        }
                    }).catch((error) => {
                        let error_data = error.response.data;

                        if (error_data) {
                            if ([30011, 30012].indexOf(error_data.error) > -1) {
                                this.errors.password.message = error_data.userMessage;
                                this.errors.password.show = true;
                                if (error_data.error === 30012) {
                                     this.tip = '更新密码'
                                }
                            }
                            if ([30020, 30010, 30021, 30030, 30031].indexOf(error_data.error) > -1) {
                                this.errors.username.show = true;
                                this.errors.username.message = error_data.userMessage;
                            }
                        }
                    })
                }
            }
        },
        mounted () {
            let u = new URLSearchParams(window.location.search);
            this.service = u.get('service');
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    }
</script>
<style>
    @import '../login.css';
</style>
