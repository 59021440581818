<template>
    <div id="cpwd">
        <div class="header">
            <img class="logo" src="@/assets/weimeng-logo.png" />
        </div>
        <div class="fpwd-box">
            <form ref="fpwdForm" :model="form">
                <div class="form-body">
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-people"></i>
                        </label>
                        <input type="text" v-model.trim="form.username" :placeholder="env==='qa'?'微盟员工测试账号':'微盟员工账号'" @change="clean_err('username')">
                        <span class="error-msg-right" v-show="errors.username.show">{{ errors.username.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-185078emailmailstreamline"></i>
                        </label>
                        <input type="text" v-model.trim="form.email" placeholder="微盟邮箱" @change="clean_err('email')">
                        <span class="error-msg-right" v-show="errors.email.show">{{ errors.email.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-yanzhengma"></i>
                        </label>
                        <input type="text" v-model.trim="form.vcode" placeholder="验证码" @change="clean_err('vcode')" style="width:350px;margin-right: 100px;">
                        <button type="button" @click="get_vcode" :disabled="vcode.disabled">{{vcode_btn_text}}</button>
                        <span class="error-msg-right" v-show="errors.vcode.show">{{ errors.vcode.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-lock"></i>
                        </label>
                        <input type="password" v-model.trim="form.new_password" placeholder="新密码(最少9位，包含大小写字母、数字与特殊字符，不含@，&)" @change="clean_err('new_password')">
                        <span class="error-msg-right" v-show="errors.new_password.show">{{ errors.new_password.message }}</span>
                    </div>
                    <div class="field-group">
                        <label>
                            <i class="iconfont oms-querenmima-copy-copy"></i>
                        </label>
                        <input type="password" v-model.trim="form.confirm_password" placeholder="确认新密码" @change="clean_err('confirm_password')">
                        <span class="error-msg-right" v-show="errors.confirm_password.show">{{ errors.confirm_password.message }}</span>
                    </div>
                    <button type="button" class="form-submit-btn" @click="changePWD">修改<span v-if="env==='qa'">测试账户</span>密码</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                vcode: {
                    disabled: false,
                    interval: null,
                    expire: 0,
                },
                send_time: '',
                form: {
                    action: 'forget',
                    username: '',
                    email: '',
                    vcode: '',
                    new_password: '',
                    confirm_password: '',
                },
                errors: {
                    username: {
                        show: false,
                        message: '请输入账户名'
                    },
                    email: {
                        show: false,
                        message: '请输入微盟邮箱'
                    },
                    vcode: {
                        show: false,
                        message: '请输入验证码'
                    },
                    new_password: {
                        show: false,
                        message: '请输入新密码'
                    },
                    confirm_password: {
                        show: false,
                        message: '请再次输入新密码确认'
                    },
                }

            }
        },
        methods: {
            validate () {
                let mark = false;
                for (let key in this.form) {
                    if (key !== 'action') {
                        this.errors[key].show = this.form[key] === '';
                        if (key === 'email') {
                            let reg = /^[a-zA-Z0-9_-_.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                            if (!reg.test(this.form.email)) {
                                this.errors.email.show = true;
                                this.errors.email.message = this.form.email === '' ? '请输入微盟邮箱' : '邮箱格式错误';
                            }
                        }
                        mark = mark || this.errors[key].show;
                    }
                }
                return !mark;
            },
            clean_err (key) {
                this.errors[key].show = false;
                switch (key) {
                    case 'username':
                        this.errors[key].message = '请输入账户名';
                        break;
                    case 'old_password':
                        this.errors[key].message = '请输入原密码';
                        break;
                    case 'new_password':
                        this.errors[key].message = '请输入新密码';
                        break;
                    case 'confirm_password':
                        this.errors[key].message = '请再次输入新密码确认';
                        break;
                    default:
                        break;
                }
            },
            get_vcode () {
                this.errors.username.show = this.form.username === '';
                this.errors.email.show = this.form.email === '';
                let reg = /^[a-zA-Z0-9_-_.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (!reg.test(this.form.email)) {
                    this.errors.email.show = true;
                    this.errors.email.message = this.form.email === '' ? '请输入微盟邮箱' : '邮箱格式错误';
                }
                let mark = this.errors.username.show || this.errors.email.show;
                if (!mark) {
                    let params = {
                        username: this.form.username,
                        email: this.form.email
                    };
                    this.$http.post('/cas/mail-code/', params).then((response) => {
                        if (response.status === 200) {
                            alert(response.data.userMessage);
                            this.send_time = response.data.send_num
                            this.vcode.disabled = true;
                            this.vcode.expire = 120+(60*(this.send_time));
                            this.vcode.interval = setInterval(() => {
                                if (this.vcode.expire > 0) {
                                    this.vcode.expire -= 1;
                                } else {
                                    clearInterval(this.vcode.interval);
                                    this.vcode.disabled = false;
                                    console.log('clear!')
                                }
                            }, 1000)
                        }
                    }).catch((error) => {
                        let error_data = error.response.data;
                        if (error_data) {
                            if ([60006].indexOf(error_data.error) > -1) {
                                this.errors.email.show = true;
                                this.errors.email.message = error_data.userMessage;
                            }
                            if ([60001].indexOf(error_data.error) > -1) {
                                this.errors.username.message = error_data.userMessage;
                                this.errors.username.show = true;
                            }
                            if ([70001, 70002].indexOf(error_data.error) > -1) {
                                alert(error_data.userMessage)
                            }
                        }
                    })
                }
            },
            changePWD () {
                if (this.validate()) {
                    this.$http.post('/cas/modify/', this.form).then((response) => {
                        if (response.status == 200) {
                            alert(response.data.userMessage);
                            history.go(-2);
                        }
                    }).catch((error) => {
                        let error_data = error.response.data;
                        if (error_data) {
                            if ([30030, 30031, 60001].indexOf(error_data.error) > -1) {
                                this.errors.username.message = error_data.userMessage;
                                this.errors.username.show = true;
                            }
                            if ([60006].indexOf(error_data.error) > -1) {
                                this.errors.email.show = true;
                                this.errors.email.message = error_data.userMessage;
                            }
                            if ([60003].indexOf(error_data.error) > -1) {
                                this.errors.new_password.show = true;
                                this.errors.new_password.message = error_data.userMessage;
                            }
                            if ([60004].indexOf(error_data.error) > -1) {
                                this.errors.confirm_password.show = true;
                                this.errors.confirm_password.message = error_data.userMessage;
                            }
                            if ([60005].indexOf(error_data.error) > -1) {
                                this.errors.vcode.show = true;
                                this.errors.vcode.message = error_data.userMessage;
                            }
                        }
                    })
                }
            },
        },
        computed: {
            vcode_btn_text() {
                if (this.vcode.expire === 0) {
                    return '发送验证码'
                } else {
                    return this.vcode.expire + '秒后重试'
                }
            },
            env () {
              return process.env.VUE_APP_ENV
            }
        },
        mounted () {}
    }
</script>
<style>
    @import '../login.css';
    #cpwd {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        background: url("../../assets/bac.svg");
    }
    .header {
        position: fixed;
        top: 0;
        width: 100%;
        height: 60px;
        background-color: rgba(60, 60, 60, 0.5);
    }
    .fpwd-box {
        width: 500px;
        height: 450px;
        background-color: rgba(255, 255, 255, 0.9);
        align-self:center;
        margin-top: -10px;
        position: relative;
        padding: 25px 25px 23px;
    }
</style>
