<template>
    <div id="co">
        <LoginCard></LoginCard>
    </div>
</template>
<script>
    import LoginCard from '@/components/login-card/login-card'
    export default {
        components: { LoginCard, },
    }
</script>
<style scoped>
    #co {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #3c3c3c;
    }
</style>

