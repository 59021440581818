<template>
    <div class="login-box ssiv">
        <div class="login-body">
            <div class="ID_form" v-show="!QRmode">
                <form ref="loginForm" :model="form">
                    <div class="form-title" style="margin-bottom: 30px;">
                        密码登录 <span v-if="env === 'qa'" style="color: #40B3DF">Alpha</span>
                    </div>
                    <div class="form-body">
                        <div class="field-group">
                            <label>
                                <i class="iconfont oms-people"></i>
                            </label>
                            <input type="text" v-model.trim="form.username" name="user" :placeholder="env==='qa'?'微盟员工测试账号':'微盟员工账号'" @change="clean_err_username">
                            <span class="error-msg-right" v-show="errors.username.show">{{ errors.username.message }}</span>
                        </div>
                        <div class="field-group">
                            <label>
                                <i class="iconfont oms-lock"></i>
                            </label>
                            <input type="password" v-model.trim="form.password" name="password" placeholder="" @change="clean_err_password">
                            <span class="error-msg-right" v-show="errors.password.show">{{ errors.password.message }}</span>
                        </div>
                        <div class="field-group">
                            <label>
                                <i class="iconfont oms-yanzhengma"></i>
                            </label>
                            <input type="text" v-model.trim="form.vcode" placeholder="验证码" @change="clean_err_vcode" style="width: 70%; margin-right: 100px;">
                            <button type="button" @click="get_vcode" style="width: 30%" :disabled="vcode.disabled">{{vcode_btn_text}}</button>
                            <span class="error-msg-right" v-show="errors.vcode.show">{{ errors.vcode.message }}</span>
                        </div>

                        <button type="button" class="form-submit-btn" @click="formSubmit">登录</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="login-footer">
            <a :href=href>{{tip}}</a>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'LoginCard',
        data() {
            return {
                QRmode: false,
                tip: '忘记密码',
                timer: null,
                uuid: '',
                QRreflush: false,
                vcode: {
                    disabled: false,
                    interval: null,
                    expire: 0,
                },
                form: {
                    username: '',
                    password: '',
                    vcode: '',
                },
                errors: {
                    username: {
                        show: false,
                        message: '账户不能为空'
                    },
                    password: {
                        show: false,
                        message: '密码不能为空'
                    },
                    vcode: {
                        show: false,
                        message: '请输入验证码'
                    },
                },
            }
        },
        watch: {
            // 判断是否要刷新
            QRreflush: function (val) {
                if (val) {
                    clearInterval(this.timer);
                } else {
                    this.timer = setInterval(() => {
                        this.qrcode()
                    }, 1000)
                }
            },
        },
        methods: {
            get_service () {
                let u = new URLSearchParams(window.location.search);
                return u.get('service');
            },
            validate () {
                let mark = false;
                for (let key in this.form) {
                    this.errors[key].show = this.form[key] === '';
                    mark = mark || this.errors[key].show;
                }
                return !mark;
            },
            clean_err_username () {
                this.errors.username.show = false;
                this.errors.username.message = '账户不能为空';
            },
            clean_err_password () {
                this.errors.password.show = false;
                this.errors.password.message = '密码不能为空';
            },
            clean_err_vcode () {
                this.errors.vcode.show = false;
                this.errors.vcode.message = '验证码不能为空';
            },
            formSubmit () {
                if (this.validate()) {
                    let service = this.get_service();
                    this.$http.post('/cas/ssiv/', {
                        username: this.form.username,
                        password: this.form.password,
                        vcode: this.form.vcode,
                    }, {
                        params: {
                            service: service
                        }
                    }).then((response) => {
                        if (response.data.redirect) {
                            window.location.href = response.data.redirect
                        }
                    }).catch((error) => {
                        let error_data = error.response.data
                        if (error_data) {
                            if ([30011, 30012].indexOf(error_data.error) > -1) {
                                this.errors.password.show = true;
                                this.errors.password.message = error_data.userMessage;
                                if (error_data.error === 30012) {
                                    this.tip = '更新密码'
                                }
                            }
                            if ([30020, 30010, 30021, 30030, 30031].indexOf(error_data.error) > -1) {
                                this.errors.username.show = true;
                                this.errors.username.message = error_data.userMessage;
                            }
                            if ([30025, 30026].indexOf(error_data.error) > -1) {
                                this.errors.vcode.show = true;
                                this.errors.vcode.message = error_data.userMessage;
                            }
                        }
                    })
                }
            },
            get_vcode () {
                if (this.form.username === '' || this.form.username === null) {
                    this.errors.username.show = true;
                    this.errors.username.message = '请先填写用户账号';
                } else {
                    this.clean_err_vcode();
                    this.$http.post('/cas/smscode/', {
                        username: this.form.username,
                    }).then((response) => {
                        if (response.data.status === 'success') {
                            alert(response.data.msg);
                            this.vcode.disabled = true;
                            this.vcode.expire = response.data.expire * 60;
                            this.vcode.interval = setInterval(() => {
                                if (this.vcode.expire > 0) {
                                    this.vcode.expire -= 1;
                                } else {
                                    clearInterval(this.vcode.interval);
                                    this.vcode.disabled = false;
                                    console.log('clear!')
                                }
                            }, 1000)
                        } else {
                            this.errors.vcode.show = true;
                            this.errors.vcode.message = response.data.msg;
                        }
                    })
                }
            },
        },
        computed: {
            vcode_btn_text() {
                if (this.vcode.expire === 0) {
                    return '发送验证码'
                } else {
                    return this.vcode.expire + '秒后重试'
                }
            },
            href(){
                // if (window.location.hostname === 'oms.hsmob.com'){
                //     return 'https://cas.hsmob.com/#/change_pwd';
                // } else {
                //     return '/#/change_pwd'
                // }
                return `${process.env.VUE_APP_BASE_URL}/#/change_pwd`;
            },
            env () {
              return process.env.VUE_APP_ENV
            }
        },
        mounted () {
            let u = new URLSearchParams(window.location.search);
            this.service = u.get('service');
            if (this.service === null || this.service === ''){
                this.vcode.disabled = true;
            }
        },
        created () {
            let service = this.get_service();
            this.$http.get('/cas/ssiv/',{
                params: {
                    service: service
                }
            }).then((response) => {
                if (response.data.redirect) {
                    window.location.href = response.data.redirect
                }
            })
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    }
</script>
<style>
    @import '../login.css';
</style>
