<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>
<style>
  *, :after, :before {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    width: 100%;
  }
  body {
    margin: 0;
    height: 100%;
    width: 100%;
    background-color: transparent!important;
  }
  button, dd, details, div, dl, dt, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, input, legend, li, menu, nav, ol, p, section, td, textarea, th, ul {
    margin: 0;
    padding: 0;
  }
  a {
    color: #2d8cf0;
    background: 0 0;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    transition: color .2s ease;
  }
  #app {
    height: 100%;
    width: 100%;
  }
</style>
