<template>
    <div id="co">
        <SsivLoginCard></SsivLoginCard>
    </div>
</template>
<script>
    import SsivLoginCard from '@/components/login-card/ssiv-login-card'
    export default {
        components: { SsivLoginCard, },
    }
</script>
<style scoped>
    #co {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #3c3c3c;
    }
</style>

