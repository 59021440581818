import Vue from 'vue'
import Router from 'vue-router'
import login from '@/views/login.vue'
import iframe from '@/components/iframe-login-card/iframe-login-card.vue'
import iframeLogin from '@/components/iframe-login-form/iframe-login-form.vue'
import ssivLogin from '@/views/ssiv-login.vue'
import ssivIframeLogin from '@/components/second-sign-in-verification/ssiv.vue'
import forgetPWD from '@/components/forget-password/forget-password.vue'
import changePWD from '@/components/change-password/change-password.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'login',
            component: login
        },
        {
            path: '/iframe',
            name: 'iframe',
            component: iframe
        },
        {
            path: '/login_form',
            name: 'login_form',
            component: iframeLogin
        },
        {
            path: '/ssiv',
            name: 'ssiv',
            component: ssivLogin
        },
        {
            path: '/ssiv_iframe',
            name: 'ssiv_iframe',
            component: ssivIframeLogin
        },
        {
            path: '/forget_pwd',
            name: 'forget_pwd',
            component: forgetPWD
        },
        {
            path: '/change_pwd',
            name: 'change_pwd',
            component: changePWD
        }
    ]
})
